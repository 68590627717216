import React, { createContext, useState, useEffect } from "react";
import { getConversionRate } from "../services/NftServices";
import { getUserProfile } from "../services/userService";
import { useAddress } from "@thirdweb-dev/react";
import { getFierceCustomer, getFierceCustomerBalance, getSoloAssets } from "../services/propertyServices";
import configURl from "../runtime.config";
import { IsWalletAddressesEqual } from "../common/utility";
export const FierceConfig = {
  fierce_verification_key: "7114a954-bbef-485c-8b01-c3702b8e3ceb",
  api_base_url: "https://dev01-partner-api.apps.decentrfy.com/api/v1"
}

export const ConversionContext = createContext();
export default function Conversions(props) {
  const [showKyc, setShowKyc] = useState(false);
  const [fierceLoader, setFierceLoader] = useState(false);
  const [swapLoader, setSwapLoader] = useState(true);
  const [showSwapAssets, setShowSwapAssets] = useState(false);
  const [showContacts, setShowContacts] = useState(false);
  const [swapAssets, setSwapAssets] = useState([]);
  const [redTokenData, setRedTokenData] = useState({});
  const [usdcTokenData, setUsdcTokenData] = useState({});
  const [tokenToSend, setTokenToSend] = useState(null);
  const [activeFierceTab, setActiveFierceTab] = useState('buy');
  const [activeSwapTab, setActiveSwapTab] = useState('convert');
  const [conversionRate, setConversionRate] = useState("");
  const [adminWalletAddress, setAdminWalletAddress] = useState("");
  const [adminReceiveWalletAddress, setAdminReceiveWalletAddress] = useState("");
  const [userId, setUserId] = useState("");
  const [userData, setUserData] = useState({});
  const [solosId, setSolosId] = useState('');
  const [fierceId, setFierceId] = useState('');
  const [mappingStatus, setMappingStatus] = useState('');
  const [availableBalance, setAvailableBalance] = useState('0');
  const address = useAddress();

  const fetchAssets = () => {
    setSwapLoader(true);
    getSoloAssets(address)
      .then((response) => {
        let allAssets = response?.data;
        setSwapAssets(allAssets);
        if (!tokenToSend) {
          setTokenToSend(allAssets?.[0]);
        } else {
          const sendToken = allAssets?.find((item) => IsWalletAddressesEqual(item?.contractAddress, tokenToSend?.contractAddress));
          setTokenToSend(sendToken);
        }
        const redToken = allAssets?.find((item) => IsWalletAddressesEqual(item?.contractAddress, configURl.redNewAddress));
        console.log(redToken, 'redTokenData');

        const usdcToken = allAssets?.find((item) => IsWalletAddressesEqual(item?.contractAddress, configURl.usdcAddress));
        setRedTokenData(redToken);
        setUsdcTokenData(usdcToken);
        setSwapLoader(false);
      })
      .catch((error) => {
        setSwapLoader(false);
        console.log(error, "error");
      });
  };

  const getCustomerBalance = () => {
    if (fierceId && solosId) {
      setFierceLoader(true);
      getFierceCustomerBalance({
        params: {
          fierceId,
          solosId
        }
      })
        .then((response) => {
          console.table([{ method: 'post', url: '/fierce/customer-balance', response: response?.data }]);
          setFierceLoader(false);
          setSolosId(response.data?.solosId);
          setFierceId(response.data?.fierceId);
          setAvailableBalance(response.data?.availableBalance)
        }).catch((error) => {
          setFierceLoader(false);
          console.log(error);
        })
    }
  };

  const getCustomer = () => {
    if (userData?.email) {
      setFierceLoader(true);
      getFierceCustomer()
        .then((response) => {
          console.clear();
          console.table([{ method: 'get', url: '/fierce/customer', response: response?.data }]);
          setFierceLoader(false);
          setSolosId(response.data?.solosId);
          setFierceId(response.data?.fierceId);
          setMappingStatus(response.data?.mappingStatus);
        }).catch((error) => {
          setFierceLoader(false);
          console.log(error);
        })
    }
  };

  const getRedConversionRate = async () => {
    try {
      const response = await getConversionRate();
      setConversionRate(response?.data?.conversionrate);
      setAdminWalletAddress(response?.data?.adminWalletAddress);
      setAdminReceiveWalletAddress(response?.data?.receivewalletaddress);
    } catch (error) {
      console.error("Error fetching nft data:", error);
    }
  };

  const getUserData = async () => {
    try {
      const response = await getUserProfile();
      setUserId(response?.data?._id);
      setUserData(response?.data);
    } catch (error) {
      console.error("Error fetching nft data:", error);
    }
  };

  useEffect(getCustomer, [userData]);
  useEffect(getCustomerBalance, [solosId, fierceId, activeFierceTab, activeSwapTab]);
  useEffect(() => {
    getRedConversionRate();
    getUserData();
  }, [address]);
  useEffect(() => {
    if (address) {
      fetchAssets()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, activeSwapTab, activeFierceTab]);

  let data = {
    solosId,
    setSolosId,
    fierceId,
    setFierceId,
    swapLoader,
    availableBalance,
    setAvailableBalance,
    fetchAssets,
    fierceLoader,
    setFierceLoader,
    mappingStatus,
    setMappingStatus,
    swapAssets,
    setSwapAssets,
    activeSwapTab,
    setActiveSwapTab,
    activeFierceTab,
    setActiveFierceTab,
    conversionRate,
    showSwapAssets,
    setShowSwapAssets,
    adminWalletAddress,
    adminReceiveWalletAddress,
    userId,
    userData,
    redTokenData,
    setRedTokenData,
    usdcTokenData,
    setUsdcTokenData,
    tokenToSend,
    setTokenToSend,
    getUserData,
    showKyc,
    setShowKyc,
    getCustomer,
    showContacts,
    setShowContacts,
    getCustomerBalance
  };

  return (
    <ConversionContext.Provider value={data}>
      {props.children}
    </ConversionContext.Provider>
  );
}
