import React, { useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { ConversionContext } from "../../Context/context";
import { useNavigate } from "react-router-dom";

function SwapAssets() {
  const { showSwapAssets, setShowSwapAssets, swapAssets, setTokenToSend } =
    useContext(ConversionContext);
  let navigate = useNavigate();

  const [searchAssets, setSearchAssets] = useState([]);
  const [search, setSearch] = useState("");

  const handleSearch = (value) => {
    setSearchAssets(
      swapAssets.filter(
        (item) =>
          item.symbol?.toLowerCase().includes(value.toLowerCase()) ||
          item.categories?.toLowerCase().includes(value.toLowerCase()) ||
          item.contractAddress?.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  return (
    <Modal
      onShow={()=>{
        setSearch("");
        setSearchAssets([]);
      }}
      show={showSwapAssets}
      onHide={() => {
        setShowSwapAssets(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title className="fs-5">Select a token</Modal.Title>
      </Modal.Header>
      <Modal.Body className="border border-0 pt-0">
        <div className="tokensearch my-2">
          <input
            type="search"
            onChange={(e) => {
              handleSearch(e.target.value);
              setSearch(e.target.value);
            }}
            className="form-control"
            placeholder="Search name or paste address"
          ></input>
        </div>
        <div className="popular-tokens">
          <h6 className="my-3 text-start">Popular Tokens</h6>
          <div className="token-list">
            {search && searchAssets.length === 0 ? (
              <h6 className="text-danger">No Asset Found !</h6>
            ) : (
              (search ? searchAssets : swapAssets)?.map((item, i) => (
                <div
                  className="tokenitems"
                  onClick={() => {
                    navigate("/Swap");
                    setTokenToSend(item);
                    setShowSwapAssets(false);
                  }}
                >
                  <div className="token-image">
                    <img src={item?.image} alt=""></img>
                  </div>
                  <div className="token-name">
                    <h6 className="mb-1">{item?.symbol}</h6>
                    <div className="d-flex justify-content-between">
                      <span>
                        {item?.categories} {item?.series}
                      </span>
                      <span className="">
                        {item?.balance
                          ? Number(
                              Number(item?.balance)
                                .toString()
                                .match(/^-?\d+(?:.\d{0,2})?/)[0]
                            ).toLocaleString()
                          : 0}
                        ($
                        {item?.conversionrate
                          ? Number(
                              Number(+item?.balance * +item?.conversionrate)
                                .toString()
                                .match(/^-?\d+(?:.\d{0,2})?/)[0]
                            ).toLocaleString()
                          : 0}
                        )
                      </span>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default SwapAssets;
